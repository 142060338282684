<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 3 de 4
            </template>
            <template v-slot:subtitle>
                Resumen de Horario
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-2" style="color:red;"  v-if="formData.cruce.length>0">
						<h5><strong>Cruce de Horario:</strong></h5>
					</div>
					<div class="p-field p-col-12 p-md-3" style="color:red;"  v-if="formData.cruce.length>0">
						<div v-for="(stats, index) in formData.cruce" :key="index">
							<div>
								{{stats.ndia}} {{stats.nhora}}	<br>
							</div>
						</div>
					</div>

                    <div class="p-field p-col-12 p-md-12">
						<DataTable :value="formData.horario" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column field="bloque" header="Hora" :sortable="true"></Column>
								<Column field="Lunes" header="Lunes">	
									<template #body="{data}">
										{{data.lun['asignatura']}}
									</template>
								</Column>
								<Column field="Martes" header="Martes">
									<template #body="{data}">
										{{data.mar['asignatura']}}
									</template>
								</Column>
								<Column field="Miercoles" header="Miércoles">
									<template #body="{data}">
										{{data.mie['asignatura']}}
									</template>
								</Column>
								<Column field="Jueves" header="Jueves">
									<template #body="{data}">
										{{data.jue['asignatura']}}
									</template>
								</Column>
								<Column field="Viernes" header="Viernes">
									<template #body="{data}">
										{{data.vie['asignatura']}}
									</template>
								</Column>
								<Column field="Sabado" header="Sábado">
									<template #body="{data}">
										{{data.sab['asignatura']}}
									</template>
								</Column>
								<Column field="Domingo" header="Domingo">
									<template #body="{data}">
										{{data.dom['asignatura']}}
									</template>
								</Column>			
						</DataTable>
						<small v-show="validationErrors.horario && submitted" style="color: red;">Debe solucionar los casos de cruce de horario</small>

					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>

    </div>
</template>

<script>

export default {
	props: {
        formData: Object
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
        }
    },
	created() {
	},
    methods: {
		nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
            this.$emit('next-page', {pageIndex: 2});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 2});
        },
		validateForm() {
            if (this.formData.cruce.length>0)
                this.validationErrors['horario'] = true;
            else
                delete this.validationErrors['horario'];

            return !Object.keys(this.validationErrors).length;
        }

    }
}
</script>